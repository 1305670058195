import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import Text from '../components/Text';
import image from '../img/portrait2.jpg';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import BackButton from '../components/BackButton';

interface Props {
  data: {
    title: string;
    text: string;
  };
}

export const AboutPageTemplate: React.FC<Props> = ({
  data: { title, text },
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  return (
    <div style={{ display: 'flex' }}>
      <ContainerInner>
        <PageTitle>{title}</PageTitle>
        {isTabletOrMobile && (
          <img src={image} style={{ height: '300px', marginBottom: '50px' }} />
        )}
        <Text>{text}</Text>
        <BackButton
          to="/"
          text="Zurück zur Startseite"
          style={{ marginTop: '30px' }}
        />
      </ContainerInner>
      {!isTabletOrMobile && <img src={image} style={{ height: '500px' }} />}
    </div>
  );
};

const AboutPage: React.FC<{ data: any }> = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate data={post.frontmatter} />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        text
      }
    }
  }
`;

const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;

  @media (min-width: 1200px) {
    margin-right: 80px;
  }
`;
